// don't import files or modules into this file
const { APP_ENV } = process.env
export let env = APP_ENV as "production" | "staging" | "development"

if (!env) {
  const hostname = typeof window !== "undefined" && window?.location?.hostname
  env = "development"
  if (hostname) {
    if (hostname.includes("staging")) {
      env = "staging"
    } else if (hostname.includes("portal")) {
      env = "production"
    }
  }
}

export const IS_PRODUCTION = env === "production"
export const IS_STAGING = env === "staging"
export const IS_DEV = env === "development"

export const SENTRY_DSN = "https://5d0371a223bb4509902f8940cb957daf@o204549.ingest.sentry.io/5741383"

export const API_URL = IS_PRODUCTION
  ? "https://greymen-production.herokuapp.com/graphql"
  : IS_STAGING
  ? "https://greymen-staging.herokuapp.com/graphql"
  : "http://localhost:5555/graphql"
// export const API_URL = IS_PRODUCTION
//   ? "https://nq-greymen.herokuapp.com/graphql"
//   : "http://localhost:5000/graphql"

const WEB_URL = IS_PRODUCTION
  ? "greymen.noquarter.co"
  : IS_STAGING
  ? "staging.greymen.noquarter.co"
  : "localhost:3000"

export const FULL_WEB_URL = `${!IS_DEV ? "https://" : "http://"}${WEB_URL}`

export const ACCESS_TOKEN = "greymen.access.token"
export const REFRESH_TOKEN = "greymen.refresh.token"

export const REFRESH_TOKEN_KEY = "refreshToken"

import { extendTheme } from "@chakra-ui/react"

import { Button } from "./components/Button"
import { Input } from "./components/Input"
import { Select } from "./components/Select"
import { Textarea } from "./components/Textarea"

export const theme = extendTheme({
  colorScheme: "pink",
  colors: {
    pink: {
      50: "#FFE3EC",
      100: "#FFD0E0",
      200: "#FFB8D0",
      300: "#FFA0C1",
      400: "#FF89B1",
      500: "#FF71A2",
      600: "#D45E87",
      700: "#AA4B6C",
      800: "#803951",
      900: "#552636",
    },
    gray: {
      50: "#FAFAFA",
      100: "#F2F2F2",
      200: "#E8E8E8",
      300: "#D3D5D5",
      400: "#ABAEAE",
      500: "#7D8080",
      600: "#525555",
      700: "#343737",
      800: "#1D2020",
      900: "#151919",
    },
    deepBlue: {
      500: "#116BE9",
    },
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: "light",
  },
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, serif",
  },
  components: {
    Button,
    Input,
    Tag: {
      baseStyle: {
        container: {
          borderRadius: 0,
        },
      },
    },
    Select,
    Textarea,
  },
})
